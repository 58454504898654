export function transformedCloudinaryVideo(videoUrl, transformation) {
  if (videoUrl.includes('https://res.cloudinary.com/dmsxfecue/video/upload')) {
    const start = videoUrl.substr(0, 50);
    const end = videoUrl.substr(50, videoUrl.length);

    return `${start + transformation}/${end}`;
  } else {
    return videoUrl;
  }
}

export function transformedCloudinaryImage(imgUrl, transformation) {
  if (imgUrl?.includes('https://res.cloudinary.com/dmsxfecue/image/upload')) {
    const start = imgUrl.substr(0, 50);
    const end = imgUrl.substr(50, imgUrl.length);

    return `${start + transformation}/${end}`;
  } else {
    return imgUrl;
  }
}
